exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-advisors-tsx": () => import("./../../../src/pages/advisors.tsx" /* webpackChunkName: "component---src-pages-advisors-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-cytocast-simulated-cell-tsx": () => import("./../../../src/pages/cytocast-simulated-cell.tsx" /* webpackChunkName: "component---src-pages-cytocast-simulated-cell-tsx" */),
  "component---src-pages-dev-animated-tsx": () => import("./../../../src/pages/dev/animated.tsx" /* webpackChunkName: "component---src-pages-dev-animated-tsx" */),
  "component---src-pages-dev-ui-tsx": () => import("./../../../src/pages/dev/ui.tsx" /* webpackChunkName: "component---src-pages-dev-ui-tsx" */),
  "component---src-pages-how-we-do-it-alt-tsx": () => import("./../../../src/pages/how-we-do-it-alt.tsx" /* webpackChunkName: "component---src-pages-how-we-do-it-alt-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-investors-tsx": () => import("./../../../src/pages/investors.tsx" /* webpackChunkName: "component---src-pages-investors-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-our-story-tsx": () => import("./../../../src/pages/our-story.tsx" /* webpackChunkName: "component---src-pages-our-story-tsx" */),
  "component---src-pages-partnership-tsx": () => import("./../../../src/pages/partnership.tsx" /* webpackChunkName: "component---src-pages-partnership-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-what-we-do-tsx": () => import("./../../../src/pages/what-we-do.tsx" /* webpackChunkName: "component---src-pages-what-we-do-tsx" */),
  "component---src-pages-work-with-us-tsx": () => import("./../../../src/pages/work-with-us.tsx" /* webpackChunkName: "component---src-pages-work-with-us-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-news-tsx": () => import("./../../../src/templates/news.tsx" /* webpackChunkName: "component---src-templates-news-tsx" */),
  "component---src-templates-overview-tsx": () => import("./../../../src/templates/overview.tsx" /* webpackChunkName: "component---src-templates-overview-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */)
}

